<template>
  <div class="students-vip">
    <div class="title">
      <span
        class="s"
        :style="{
          color: $store.state.vipIndex == 1 ? '#ee4d2d' : '',
          'border-color': $store.state.vipIndex == 1 ? '#ee4d2d' : '',
        }"
        @click="handleClick(1)"
        >学生列表</span
      >
      <span
        class="s"
        :style="{
          color: $store.state.vipIndex == 2 ? '#ee4d2d' : '',
          'border-color': $store.state.vipIndex == 2 ? '#ee4d2d' : '',
        }"
        @click="handleClick(2)"
        >买家列表</span
      >
    </div>
    <div class="search-cell" v-if="$store.state.vipIndex == 1">
      <el-form
        size="mini"
        ref="form"
        :inline="true"
        :model="form"
        label-width="60px"
      >
        <el-form-item label="用户名">
          <el-input
            placeholder="用户名"
            style="width: 120px"
            v-model="form.username"
          ></el-input>
        </el-form-item>
        <el-form-item label="学号">
          <el-input
            placeholder="学号"
            style="width: 120px"
            v-model="form.student_no"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            style="width: 120px"
            v-model="form.status"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" value="1"></el-option>
            <el-option label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属学校" style="margin: 0 20px">
          <el-select
            style="width: 120px"
            v-model="form.school_id"
            placeholder="全部"
            @change="getClassLt"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in schoolList"
              :key="item.id"
              :label="item.school_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属班级">
          <el-select
            style="width: 120px"
            v-model="form.class_id"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.class_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
              page = 1;
              getVipList();
            "
            >查询</el-button
          >
          <el-button plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="search-cell" v-if="$store.state.vipIndex == 2">
      <el-form
        size="mini"
        ref="form2"
        :inline="true"
        :model="form2"
        label-width="60px"
      >
        <el-form-item label="用户名">
          <el-input
            placeholder="用户名"
            style="width: 120px"
            v-model="form2.username"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属学校" style="margin: 0 20px">
          <el-select
            style="width: 120px"
            v-model="form2.school_id"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in schoolList"
              :key="item.id"
              :label="item.school_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="所属班级">
          <el-select
            style="width: 120px"
            v-model="form.region"
            placeholder="请选择活动区域"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button
            type="primary"
            @click="
              page = 1;
              getVipList();
            "
            >查询</el-button
          >
          <el-button plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab" v-if="$store.state.vipIndex == 1">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="80"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="member_username"
          label="用户名"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="student_code"
          label="学号"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="register_time"
          label="注册时间"
          width="180"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.register_time | formatTimeS("all") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="login_times"
          label="登录次数"
          width="100"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="login_time"
          label="最后登录时间"
          width="180"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.login_times != 0">
              <div>{{ scope.row.login_time | formatTimeS("all") }}</div>
              <div style="margin-top: -6px">IP：{{ scope.row.login_ip }}</div>
            </div>
            <div v-else>无</div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="status"
          label="状态"
          width="80"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.state == 0 ? "禁用" : "启用" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="class_name"
          label="所属班级"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        layout="prev, pager, next"
        :page-size="page_size"
        :total="tableData.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <div class="tab" v-if="$store.state.vipIndex == 2">
      <el-table
        size="small "
          v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="80"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="member_name"
          label="用户名"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="register_time"
          label="注册时间"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.register_time | formatTimeS("all") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="login_times"
          label="登录次数"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="login_time"
          label="最后登录时间"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.login_times != 0">
              <div>{{ scope.row.login_time | formatTimeS("all") }}</div>
              <div style="margin-top: -6px">IP：{{ scope.row.login_ip }}</div>
            </div>
            <div v-else>无</div>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
        <!-- <el-table-column
          show-overflow-tooltip
          align="center"
          prop="address"
          label="所属班级"
        >
        </el-table-column> -->
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        layout="prev, pager, next"
        :page-size="page_size"
        :total="tableData.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getSchoolList,
  getClassList,
  buyerList,
  studentList,
} from "@/api/admin.js";
export default {
  data() {
    return {
      form: {
        username: "",
        student_no: "",
        status: "",
        school_id: "",
        class_id: "",
      },
      form2: {
        username: "",
        school_id: "",
      },
      schoolList: [],
      classList: [],
      tableData: { list: [] },
      page: 1,
      page_size: 10,
      loading: true,
    };
  },
  created() {
    this.getCampusList();
    this.getClassLt();
    this.getVipList();
  },
  methods: {
    getVipList() {
      this.loading = true;
      if (this.$store.state.vipIndex == 1) {
        studentList({
          ...this.form,
          page: this.page,
          page_size: this.page_size,
        }).then((res) => {
          if (res.code == 1) {
            this.loading = false;
            this.tableData = res.data;
          }
        });
      } else {
        buyerList({
          ...this.form2,
          page: this.page,
          page_size: this.page_size,
        }).then((res) => {
          this.loading = false;
          if (res.code == 1) {
            this.tableData = res.data;
          }
        });
      }
    },
    getCampusList() {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.schoolList = res.data;
        }
      });
    },
    getClassLt() {
      this.form.class_id = "";
      getClassList({
        school_id: this.form.school_id,
      }).then((res) => {
        this.classList = res.data;
      });
    },
    handleClick(i) {
      this.$store.state.vipIndex = i;
      this.$store.state.adminPath = i == 1 ? "studentsVip" : "buyersVip";
      this.tableData.list = [];
      this.handleReset();
    },
    handleReset() {
      this.form = {
        username: "",
        student_no: "",
        status: "",
        school_id: "",
        class_id: "",
      };
      this.form2 = {
        username: "",
        school_id: "",
      };
      this.page = 1;
      this.getVipList();
    },
    pageChange(index) {
      this.page = index;
      this.getVipList();
    },
  },
};
</script>
<style lang="less" >
.students-vip {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      border-bottom: 2px solid transparent;
      padding: 0 10px;
      padding-bottom: 10px;
      cursor: pointer;
    }
  }
  .search-cell {
    padding: 20px 0 0;
  }
}
.el-form-item__label {
  font-size: 12px;
}
</style>